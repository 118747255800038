.AuthForm .FormControl {
  border: 1px solid #979797;
  border-radius: 0.25rem;
  display: block;
  font-size: 1rem;
  line-height: 3;
  margin-top: 1.5rem;
  padding: 0 1rem;
  width: 100%;
}

.AuthForm__Submit {
  margin-top: 1.5rem;
}
