@import 'theme.scss';
@import 'components/sparkles/tokens';

.panel {
  margin-top: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;

  @media (max-width: 576px) {
    margin-top: 1.5rem;
  }
}
.panelCenter {
  p {
    margin: 0 auto;
    text-align: center;
    width: 60%;
    @media (max-width: 576px) {
      width: 80%;
    }
  }
  svg {
    margin-bottom: 1rem;
    g {
      fill: var(--color-primary);
    }
  }
}

.panelLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: 1rem;
    g {
      fill: var(--color-primary);
    }
  }
}

.panelOutlineColor {
  border: 1px solid $color-gray;
}

.panelOutlineColor-danger {
  border: 1px solid var(--brand-danger);
}

.background {
  background-color: $color-gray-extra-light;
}

.title {
  margin: 0 auto 1rem auto;
  width: 60%;
}

.titleHighlighted {
  background-color: var(--color-primary);
  color: $color-white;
  margin-top: -2rem;
  padding: $padding-cozy;
}

.textCenter {
  p {
    text-align: center;
  }
}

.textLeft {
  p {
    text-align: left;
    width: 80%;
  }
}

.textColor-danger {
  color: var(--brand-danger);
}

.iconColor-danger {
  svg {
    g {
      fill: var(--brand-danger);
    }
  }
}

.iconColor-danger {
  color: var(--brand-danger);
}

.titleBottomMargin {
  margin-bottom: $margin-comfortable;
}
