@import 'theme.scss';

.checkListItem {
  box-shadow: 0 0 8px 0 rgba(193, 193, 193, 0.5);
  margin-bottom: $space-xs;
  text-align: left;
}

.header {
  align-items: center;
  display: flex;
}

.icon {
  border-right: 1px solid $color-gray-light;
  color: $color-gray-dark;
  display: flex;
  justify-content: center;
  padding: $padding-cozy;
  width: 80px;
}

.x .icon {
  color: var(--brand-danger);
}

.check .icon {
  color: var(--brand-success);
}

.title {
  flex: 1;
  font-weight: $font-weight-bold;
  padding: $padding-cozy;
}

.description {
  border-top: 1px solid $color-gray-light;
  display: none;
  padding: $padding-cozy;
}

.description__open {
  display: block;
}

.toggle {
  align-items: center;
  background: transparent;
  border-color: var(--color-primary);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: $text-sm;
  height: 2rem;
  justify-content: center;
  margin: 0 $space-xs 0 $space-xxs;
  width: 2rem;

  &:active,
  &:focus,
  &:hover {
    border-color: var(--color-primary-darkened);
    color: var(--color-primary-darkened);
  }
}

@media print {
  .description {
    display: block;
  }
}
