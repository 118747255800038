@import 'theme.scss';

.card {
  box-shadow: 0 0 8px 0 rgba(193, 193, 193, 0.5);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 8rem auto;
  grid-template-rows: 8rem;
  grid-gap: 1rem;
  padding-right: 1rem;
}

.card h3 {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.2rem;
}

.card h4 {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
}

.card header {
  color: $color-black;
  margin-bottom: 0.5rem;
}

.headshot {
  height: 8rem;
  object-fit: cover;
  width: 8rem;
}

.summary {
  color: $color-gray-dark;
  font-size: 0.8rem;
  margin: 0.75rem 0;
  position: relative;
}

.summary a,
.summary a:visited {
  color: $color-gray-dark;
  text-decoration: none;
}

.summary a:active,
.summary a:focus,
.summary a:hover {
  color: $color-black;
}

.urls {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.urls a {
  margin-right: 0.3rem;
}

@media (min-width: 768px) {
  .card {
    grid-gap: 1.5rem;
  }
}
