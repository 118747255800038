@import 'theme.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 3rem auto;
  max-width: 960px;
  min-height: calc(100vh - #{$nav-height});
}

.narrow {
  max-width: 30rem;
}
