@import 'theme.scss';
@import 'components/sparkles/tokens';

.body {
  min-height: 35rem;
}

.container {
  box-shadow: 0 0 20px 0 rgba(103, 122, 134, 0.25);
  padding: $space-sm;
  max-width: 750px;
  margin: $space-sm $space-xs;

  @media (min-width: $breakpoint-md) {
    margin-top: $space-lg;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    @include h3titlestyles();

    color: $color-gray-dark;
    text-transform: uppercase;
  }
}

.address {
  background-color: $color-gray-light;
  display: flex;
  flex-direction: column;
  margin-bottom: $space-xs;
  padding: $space-xs;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    margin: 0;

    @media (max-width: $breakpoint-sm) {
      div {
        justify-content: left !important;
      }
    }
  }
}
