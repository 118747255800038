@import 'theme.scss';
@import 'components/sparkles/tokens';

.AddressForm {
  line-height: 2rem;
  text-align: left;
  padding: 0.25rem;
}

.AddressForm__address {
  grid-area: address;
}

.AddressForm__email {
  grid-area: email;
}

.AddressForm__CustomField {
  padding: 0;
}

.AddressForm__input {
  border: 1px solid $color-gray-dark;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25;
  padding: 1rem;
  width: 100%;
}

.AddressForm__formGroup {
  margin-bottom: 1rem;
}

.AddressForm__label {
  font-weight: 600;
  margin-bottom: 1rem;
}

.AddressForm__label--light {
  color: $color-black;
}

.AddressForm__label--dark {
  color: $color-white;
}

.AddressForm__checkbox {
  padding-bottom: 1rem;
}

#notifications_opt_in_label {
  padding: $padding-cozy 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
}

#notifications_opt_in {
  margin-right: $margin-compact;
}

#notifications_opt_in:focus {
  box-shadow: 2px 2px 2px blue;
}

.AddressForm--lg .AddressForm__input {
  font-size: 1.25rem;
}

// override Sparkles button styles to line up button
.AddressForm__submit.AddressForm__submit {
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  grid-area: submit;
  padding: 0.75rem 1.5rem;
}

.AddressForm__submit.AddressForm__submit-width {
  width: 100%;
}

.AddressForm--lg .AddressForm__submit {
  padding: 1rem;
}

.HomeScene__Box .AddressForm__submit-margin {
  margin-top: 2rem;
}

.AddressForm__map {
  grid-area: map;
  max-height: 16rem;
  min-height: 16rem;
}

.AddressForm__locale {
  grid-area: locale;
}

.AddressForm__button-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.AddressForm__cancel {
  margin-bottom: $margin-compact;
}

@media (min-width: 768px) {
  .AddressForm {
    align-items: flex-start;
    display: grid;
    grid-gap: 0.5rem 1rem;
    grid-template-areas:
      'address address submit'
      'map map map'
      'locale none none';
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: max-content;
  }

  .AddressForm__formGroup {
    margin-bottom: 0;
  }

  .AddressForm--lg .AddressForm__submit {
    padding: 1rem 2rem;
  }

  .AddressForm__button-group {
    flex-direction: row;
  }

  .AddressForm__cancel {
    margin-right: $margin-cozy;
    margin-bottom: 0rem;
  }
}
