@import 'theme.scss';

.NotFoundScene__Header {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$nav-height});
  justify-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .NotFoundScene {
    height: calc(100vh - #{$nav-height-lg});
  }
}
