.MeasureShareCard__Selection {
  margin-top: 1.5rem;
  display: flex;
}

.MeasureShareCard__Icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.MeasureShareCard__Icon--danger {
  color: var(--brand-danger);
}

.MeasureShareCard__Icon--success {
  color: var(--brand-success);
}
