@import 'theme.scss';

.ecNavButton {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  @media (max-width: 576px) {
    padding: 0.3rem;
  }

  :global(#Icon_dashboard) {
    fill: getColor(var(--color-primary), $color-primary);
  }

  @if var(--color-primary) {
    :global(#Rectangle) {
      fill: $color-gray-light;
    }

    &:hover {
      :global(#Rectangle) {
        fill: $color-gray;
      }
    }
  } @else if $color-primary {
    :global(#Rectangle) {
      fill: scale-color($color-primary, $lightness: 90%);
    }

    &:hover {
      :global(#Rectangle) {
        fill: scale-color($color-primary, $lightness: 80%);
      }
    }
  }
}
