.IneligibleModal {
  text-align: center;
}

.Ineligiblemodal__LeadForm .Button--success {
  background: var(--brand-success);
}

@media (min-width: 768px) {
  .Ineligiblemodal__LeadForm {
    align-items: center;
    display: flex;
  }
}
