.textLine {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: #979797;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.textLine::before,
.textLine::after {
  content: '';
  flex-grow: 1;
  background: #979797;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
}

.textLine::before {
  margin-right: 1rem;
}

.textLine::after {
  margin-left: 1rem;
}
