@import 'theme.scss';

.subnav {
  border: 1px solid $color-gray;
  display: flex;
  height: 4rem;
  justify-content: center;

  button {
    margin: 0.7rem 0.5rem;
  }

  @media (max-width: $breakpoint-md) {
    overflow: scroll;
    justify-content: left;
  }
}
