@import 'theme.scss';

.hexagon {
  display: inline-block;
  font-size: 0; /* Required to remove space between inline-block elements */
  height: 52px;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.body {
  color: $color-white;
  font-size: $text-xl;
  font-weight: $font-weight-regular;
  height: 52px;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 47px;
}

.trapezoid {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  display: inline-block;
  height: 52px;
  width: 0;
}

:global(.trapezoid__left) {
  border-right: 24px solid var(--color-primary);
}

:global(.trapezoid__right) {
  border-left: 24px solid var(--color-primary-darkened);
}
