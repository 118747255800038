@import 'theme.scss';

.searchContainer {
  position: relative;
}

.search {
  background-color: $color-white;
  border: 1px solid $color-gray-dark;
  border-radius: 0.25rem;
  display: flex;

  width: 44rem;

  input {
    border: unset;
    font-size: 1rem;
    line-height: 1.25;
    padding: 1rem;
    padding-left: 0.5rem;
    width: 100%;

    &::placeholder {
      font-style: normal;
      @media (max-width: 991px) {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    color: getColor(var(--color-primary), $color-primary);
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1rem;
    width: 20px !important;

    @media (max-width: 991px) {
      margin-left: 0.8rem;
      width: 15px !important;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.back {
  color: getColor(var(--color-primary), $color-primary);
  font-size: 0.8rem;
  padding-left: 1rem;
  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
}

.mobileSearchContainer {
  @media (max-width: 991px) {
    background-color: $color-white;
    height: 100%;
    padding-top: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 5rem;
  }

  .search {
    border: 1px solid $color-gray;
    box-shadow: unset;
    margin: 1rem;
    width: calc(100% - 2rem);
    &:focus {
      border: getColor(var(--color-primary), $color-primary);
    }
  }
}

.isInNav {
  margin: auto 2rem;
  width: 30rem;

  .search {
    width: 30rem;
  }
}

.loading {
  margin: auto 0.8rem;
}
