@import 'theme.scss';

.containerWithBackground {
  width: $space-xl;
  text-align: center;
}

.container {
  text-align: left;
}

.addressBox {
  text-align: left;
  width: $space-xl;
  span {
    color: getColor(var(--color-primary), $color-primary);
    font-size: $text-xs;
    font-weight: $font-weight-bold;
  }

  p {
    margin: $m0;
  }
}

.addressLink {
  color: var(--link-color);
  font-size: $text-xs;
  margin-top: $space-xxs;

  &:hover {
    color: var(--link-color-hover);
    cursor: pointer;
    text-decoration: underline;
  }
}

.withBackground {
  background: $color-gray-light;
  margin: 1rem auto 0.5rem;
  width: 100%;
  text-align: center;
  padding: $padding-cozy;

  span {
    color: $color-black;
    display: block;
    font-size: $text-xs;
    font-weight: $font-weight-bold;
    margin-bottom: $space-xxs;
    text-transform: uppercase;
  }

  p {
    font-weight: $font-weight-bold;
    line-height: $line-height-md;
    margin: $m0;
  }

  @media (max-width: $breakpoint-sm) {
    width: 80%;
  }
}
