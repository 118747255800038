@import 'theme.scss';

.SelectionComment {
  position: relative;
}

.SelectionComment__Comment {
  background: $color-gray-extra-light;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
  padding: 0.1rem 1.5rem;
}

.SelectionComment__Form {
  align-items: center;
  display: flex;
  margin: 1.5rem 0;
}

.SelectionComment__Form textarea {
  border: none;
  box-shadow: 0 2px 8px 0 rgba(113, 111, 111, 0.22);
  margin-right: 1.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.SelectionComment__Edit {
  color: var(--link-color);
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.SelectionComment__Edit:hover {
  color: var(--link-color--hover);
}
