@import 'theme.scss';

.root {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  a {
    flex: 0;
    margin: 0 $space-xxs $space-xs 0;
    padding: 5px;
    &:last-child {
      margin-right: 0;
    }
    &:visited {
      color: $color-gray-dark;
    }

    @media (max-width: $breakpoint-sm) {
      min-width: 32px;
      min-height: 32px;
      margin: 0 $space-xs $space-xs 0;
    }
  }
}
