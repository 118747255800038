.root {
  align-items: center;
  background: var(--brand-warning);
  bottom: 2rem;
  box-shadow: -1px 0px 3px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  font-size: 0.875rem;
  height: 3rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: fixed;
  right: -11rem; // Hide
  transition: all 0.15s ease-in-out;
  width: 15rem;
  z-index: 2147483647;
}

.open {
  right: 0;
}

.icon {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
