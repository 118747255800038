@import 'theme.scss';

.container {
  margin: $margin-comfortable-vertical-only;
  text-align: center;
}

.icon {
  font-size: $text-lg;
}

.title {
  color: $color-gray-dark;
  letter-spacing: 1px;
  margin: $margin-compact-vertical-only;
  text-transform: uppercase;
}

.list {
  list-style: none;
  margin: $m0;
  padding: $p0;
}

.item {
  margin-bottom: $space-xxs;
}

.snippet {
  justify-content: center;
  margin: $margin-cozy-vertical-only;
}

@media (min-width: $breakpoint-md) {
  .snippet {
    margin: $m0;
  }
}
