@import 'theme.scss';

.alert {
  background-color: var(--brand-success);
  color: $color-white;
  display: flex;
  font-size: 0.9rem;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;

  @media (min-width: 576px) {
    padding-left: 3rem;
  }

  p {
    max-width: 40rem;
    margin: 0 auto;
    padding: 1rem;
    @media (max-width: 576px) {
      line-height: 1.3;
    }
    @media (min-width: 576px) {
      padding: 2rem;
    }
  }
}

.close {
  background: transparent;
  border: none;
  color: $color-white;
  cursor: pointer;
  display: flex;
  opacity: 0.7;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: right;
}
