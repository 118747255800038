@import 'theme.scss';

/* Class added to <body>. Prevents page from scrolling behind modal. */
.ReactModal__Body--open {
  overflow: hidden;
}

.Modal {
  background: $color-white;
  box-shadow: 0 0 12px 0 rgba(134, 134, 134, 0.5);
  border-radius: 0.5rem;
  margin: 1rem;
  max-height: calc(100vh - 2rem); /* -2rem to account for margin */
  outline: 0;
  overflow-y: auto;
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.Modal--after-open {
  transform: translate(0, 0);
}

.Modal--before-close {
  transform: translate(0, -25%);
}

.Modal__Overlay {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  transition: opacity 0.15s linear;
  z-index: 2147483647;
}

.Modal__Overlay--after-open {
  opacity: 1;
}

.Modal__Overlay--before-close {
  opacity: 0;
}

.Modal__Body {
  padding: 1rem;
}

.Modal__Header {
  position: sticky;
  top: 0;
  width: 100%;
}

.Modal__BtnClose {
  background: transparent;
  border: none;
  color: $color-gray;
  cursor: pointer;
  float: right;
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem;
}

.Modal__BtnClose:hover {
  color: $color-gray-dark;
}

.Modal__BtnClose:focus {
  outline: auto !important;
}

@media (min-width: 612px) {
  .Modal {
    margin: 4rem auto 1.5rem auto;
    max-height: calc(100vh - 5.5rem); /* -5.5rem to account for margin */
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .Modal__Body {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
