@import 'theme.scss';

.root {
  align-items: center;
  background: $color-black;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: 0.75rem;
  height: 1.5rem;
  justify-content: center;
  margin-right: 1rem;
  width: 1.5rem;
}
