@import 'theme.scss';

.root {
  color: $brand-disabled;
  display: block;
}

.checked {
  color: var(--brand-success);
}
