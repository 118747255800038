@import 'theme.scss';

.snippet {
  display: flex;
  color: $color-black !important;
  min-height: 2rem;
  text-decoration: none;

  &:not(:last-of-type) {
    margin-bottom: $space-xs;
  }

  &:hover {
    text-decoration: underline;
  }

  sup {
    color: $color-gray;
    font-size: 0.675rem;
    margin-left: 0.375rem;

    &:hover {
      color: var(--link-color--hover);
    }
  }
  p {
    margin: $m0;
  }
}
