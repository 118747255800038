/* TODO - we currently have two next step buttons in maptv and request ballot.
These should bd reconciled and the styling should be universally adapted, because
for some reason, the styles from the other module are overriding any styles here.
*/

.NextStepButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.65rem 5rem;
}
