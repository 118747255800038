@import 'theme.scss';

.ProgressBar progress {
  background: $color-gray;
  border: none;
  border-radius: 0.25rem;
  color: var(--color-primary);
  display: block;
  flex: 1;
  height: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.ProgressBar--white progress {
  background: $color-white;
}

.ProgressBar--compact progress {
  margin-left: 0;
  margin-right: 1rem;
}

.ProgressBar progress::-moz-progress-bar {
  background: var(--color-primary);
  border: none;
  border-radius: 0.25rem;
}

.ProgressBar progress::-webkit-progress-bar {
  background: $color-gray;
  border-radius: 0.25rem;
}

.ProgressBar--white progress::-webkit-progress-bar {
  background: $color-white;
}

.ProgressBar progress::-webkit-progress-value {
  background: var(--color-primary);
  border-radius: 0.25rem;
}
