@import 'theme.scss';

.excuseDescription p {
  display: inline;
}

.excuseMoreInfo {
  font-size: 0.8rem;
  font-style: italic;
  color: $color-gray-dark;
  margin-left: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
}
