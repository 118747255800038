@import 'theme.scss';

.EndorsementList {
  text-align: center;
}

.EndorsementList__Button {
  background: transparent;
  border: none;
  color: var(--link-color);
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.EndorsementList__Button:hover {
  color: var(--link-color--hover);
}

.EndorsementList__List {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}

.EndorsementList__List li {
  align-items: center;
  border: 1px solid $color-gray-light;
  border-left: 0.325rem solid $color-black;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  max-width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.EndorsementList__List li > a,
.EndorsementList__List li > span,
.EndorsementList__List li > a:visited {
  color: $color-black;
  text-decoration: none;
}

.EndorsementList__List li > a:active,
.EndorsementList__List li > a:focus,
.EndorsementList__List li > a:hover {
  color: var(--link-color--hover);
}
