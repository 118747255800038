@import 'theme.scss';

.barAssociationEvaluations {
  text-align: center;
  h2 {
    letter-spacing: 2px;
    margin: 0 0 $space-sm;
    text-transform: uppercase;
  }
}

.highlyQualified svg {
  color: #5f9f9e;
}

.qualified svg {
  color: #e0b46c;
}

.notQualified svg {
  color: #dc7e6d;
}

.notEvaluated svg {
  color: #858180;
}

.total {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-bold;
}

.total .hexagon {
  margin-top: $space-xs;
  margin-bottom: $space-xs;
}

.highlyQualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #5f9f9e;
  }
  :global(.trapezoid__right) {
    border-left-color: #407f7e;
  }
}

.qualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #e0b46c;
  }
  :global(.trapezoid__right) {
    border-left-color: #d8a552;
  }
}

.notQualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #dc7e6d;
  }
  :global(.trapezoid__right) {
    border-left-color: #cf5d48;
  }
}

.notEvaluatedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #858180;
  }
  :global(.trapezoid__right) {
    border-left-color: #474747;
  }
}

@media (min-width: $breakpoint-md) {
  .totals {
    display: flex;
    justify-content: space-around;
    margin-top: $space-sm;
  }
  .total .Hexagon {
    margin-bottom: $space-xxs;
  }
}
