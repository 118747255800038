@import 'theme.scss';

.SharedBallot {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  padding: 3rem 1rem;
}

.SharedBallot h2 {
  color: $color-gray-dark;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

.SharedBallot__Section {
  margin-bottom: 3rem;
}
