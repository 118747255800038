@import 'theme.scss';

.candidateHeader {
  margin-bottom: $space-sm;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  text-align: center;

  h1 {
    letter-spacing: 1px;
    margin: $m0;
    text-transform: uppercase;
  }

  h2 {
    color: $color-gray-dark;
    font-size: $text-sm;
    font-weight: $font-weight-regular;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: $space-xxs;
  }
}

.urls {
  margin-top: $space-xs;
}

.runningMate {
  color: $color-gray-dark;
  margin-bottom: $space-xs;
  font-weight: $font-weight-regular;
}

.headshot {
  margin-bottom: $space-xs;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 150px;
}

.featured {
  background: var(--brand-success);
  border-radius: 0.15rem;
  bottom: 0;
  color: $color-white;
  font-size: 0.675rem;
  left: 0;
  padding: 0.5rem;
  right: 0;
  text-align: center;
  text-transform: uppercase;

  svg {
    margin-right: $space-xxs;
  }
}

.notification {
  margin-bottom: $space-sm;
}
