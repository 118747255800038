@import 'theme.scss';

.buttonGroup {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.buttonGroup a {
  flex: 0;
  margin: 0 0.5rem 1rem 0;
}

.buttonGroup a:last-child {
  margin-right: 0;
}

a.button {
  background: $color-gray-light;
  box-shadow: var(--color-primary) 2px 2px 0;
  color: var(--color-primary);
  margin-right: 0.3rem;
}

a.button:active,
a.button:hover {
  background: $color-gray;
  color: var(--color-primary);
}
