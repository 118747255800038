@import 'theme.scss';

.root {
  flex: 1;
  font-weight: bold;
  margin: $margin-cozy-horizontal-only;
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    margin-bottom: $space-xs;
  }
}

.icon {
  margin: 0 auto 15px auto;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media (max-width: $breakpoint-sm) {
    padding: 1rem 2rem;
  }
}

a.link {
  color: $color-black;
  text-decoration: none;
}
