@import 'theme.scss';

.CommandBar {
  align-items: center;
  background: $color-white;
  border-bottom: 1px solid $color-gray;
  display: flex;
  height: $nav-height;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.CommandBar__Children {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.CommandBar__BackBtn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 768px) {
  .CommandBar {
    height: $nav-height-lg;
  }
}

@media print {
  .CommandBar {
    display: none;
  }
}
