@import 'theme.scss';
@import 'components/sparkles/tokens';

.socialSidebar {
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding-right: $space-xs;
  right: 0;
  z-index: 2147483647;
}
