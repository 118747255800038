@import 'theme.scss';

.notification {
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  font-size: $text-xs;
  justify-content: space-between;
  line-height: $line-height-sm;
  margin-bottom: $space-xs;
  padding: $padding-cozy;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.body {
  margin: $margin-cozy-horizontal-only;
  width: 100%;
}

.close {
  background: transparent;
  border: none;
  color: $color-white;
  cursor: pointer;
  opacity: 0.7;
  padding: $p0;
  text-align: right;
}

.warning {
  .close {
    color: $color-black;
    &:hover {
      opacity: 1;
    }
  }
}

.icon {
  font-size: 1.75rem;
}

.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.success {
  background-color: var(--brand-success);
  border-color: var(--brand-success);
  color: $color-white;
}

.warning {
  background-color: #fff3cd;
  color: #856404;
}

.sm {
  padding: $space-xxs $space-xs;
  .icon {
    font-size: $text-lg;
  }
}

.xs {
  padding: 0;
  .icon {
    font-size: $text-md;
  }
}
