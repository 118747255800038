@import 'theme.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 35rem;

  /*
  yes, we are using magic number breakpoints here :(
  it's not ideal but it corresponds to the breakpoint in
  civic-engines/src/reducer.ts. TODO(eth): make these responsive
  state reducer breakpoints align with our Sparkles breakpoint tokens
  */

  &:not(.meetYourRepListContainer) {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}

.hideResults {
  ul,
  .categoryFilter {
    display: none;
  }
}

.listView {
  height: 100%;

  @media (min-width: 767px) {
    min-width: $space-xl;
    max-width: 30%;
    width: 30rem;
  }
}

.container:not(.meetYourRepListContainer) .listView {
  @media (min-width: 767px) {
    border-right: 1px solid $color-gray-light;
  }
}

.hidden {
  display: none;
}

.bodyContent {
  width: 100%;
}

.meetYourRepListContainer .bodyContent {
  @media (min-width: 767px) {
    margin-top: $margin-cozy;
    margin-right: $margin-cozy;
  }

  @media (min-width: 767px) and (max-width: 950px) {
    max-width: 59%;
  }
}

.hideListItems .bodyContent {
  display: none;
}

.meetYourRepListContainer > div:first-of-type {
  @media (min-width: 767px) {
    max-width: 50%;
    width: 50rem;
  }
}

.categoryFilter {
  padding-left: 1rem;
  font-weight: $font-weight-bold;
  font-size: $text-sm;
  font-variant: small-caps;
}
