@import 'theme.scss';

.container_outer {
  position: relative;
  height: $space-md;
}

.candidateListContainer {
  border-bottom: none;
  box-shadow: none;
  margin-bottom: 0;
  margin-top: $space-xxs;
  min-height: 6rem;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $breakpoint-md) {
    .hasBadge {
      min-height: 12rem;
    }
  }
}

.candidateList {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: $m0;
  min-width: 100%;
  overflow: scroll;
  padding: $p0;
  position: absolute;
}

.nav {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  position: absolute;
  width: 100%;
}

.right_only {
  justify-content: flex-end;
}

.navBtn {
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid $color-gray;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 4px 0 rgba(128, 128, 128, 0.5);
  cursor: pointer;
  font-size: $text-xl;
  margin: $m0;
  padding: $space-xxs $space-xxs;
  width: 32px;
  z-index: 100;

  &:hover {
    background: rgba(255, 255, 255, 1);
    color: var(--link-color--hover);
  }
}

.hidden {
  display: none;
}

.item {
  list-style: none;
  &:first-child {
    margin-left: $space-xxs;
  }
  &:last-child {
    margin-right: $space-xxs;
  }
}
