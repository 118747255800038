@import 'theme.scss';

/* Default: 50x50 */
.Headshot {
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
  padding: $p0;
  width: 50px;
}

.shadow {
  box-shadow: 0 0 12px 0 rgba(193, 193, 193, 0.5);
}

/* Large: 100x100 */
.lg {
  height: 100px;
  width: 100px;
}

/* Small: 30x30 */
.xs {
  height: 30px;
  margin: $m0;
  width: 30px;
}

.withdrawn {
  filter: grayscale(1);
}

@media (min-width: $breakpoint-md) {
  .responsive {
    .lg {
      height: 150px;
      width: 150px;
    }
  }
}

.border {
  border-radius: 50%;
  height: 66px;
  width: 66px;
  z-index: 1;
}
