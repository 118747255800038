@import 'theme.scss';

.flowChoice {
  display: flex;
  max-width: 600px;
  width: 100%;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
  }
}
