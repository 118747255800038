@import 'theme.scss';

.StepHeader {
  margin: 3rem auto 1.5rem auto;
  padding: 0 1rem;
  text-align: center;
  width: 80%;
  @media (max-width: 576px) {
    width: 100%;
  }
}

.StepHeader h2 {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.StepHeader h2 span {
  color: $color-black;
}

.StepHeader p {
  color: $color-gray-dark;
  margin: 0 auto 1rem auto;
  max-width: 44rem;
}

@media (min-width: 768px) {
  .StepHeader h2 {
    font-size: 1.5rem;
  }

  .StepHeader p {
    font-size: 1.125rem;
  }
}
