@import 'theme.scss';

.panel {
  background: $color-white;
  box-shadow: 0 0 8px 0 $color-gray;
  color: $color-black;
}

.button {
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 8px 0 var(--link-color--hover);
  }
}
