@import 'theme.scss';

.excuseBtn {
  align-items: center;
  background: transparent;
  border: 0;
  border-top: 1px solid $color-gray;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  text-align: left;
  padding: 1.5rem 1rem;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $color-gray;
  }

  .expanded {
    font-weight: normal;
  }

  &:hover {
    color: $color-black;
    background-color: $color-gray-extra-light;

    svg {
      color: var(--brand-success-light);
    }
  }
}

.selectedExcuse {
  background-color: #f2fbf7;
}

.excuseBtnIcon {
  margin-right: 1rem;
}
