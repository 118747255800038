@import 'theme.scss';

.LinkButton,
.LinkButton:visited {
  align-items: center;
  background: $color-white;
  border-color: $color-gray-dark;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  color: $color-gray-dark;
  display: inline-flex;
  font-size: 1rem;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
}

.LinkButton--sm {
  height: 1.25rem;
  font-size: 0.75rem;
  width: 1.25rem;
}

.LinkButton:active,
.LinkButton:focus,
.LinkButton:hover {
  border-color: var(--color-primary-darkened);
  color: var(--color-primary-darkened);
}
