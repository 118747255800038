.PositionShareCard ul {
  list-style: none;
  padding: 0;
}

.PositionShareCard__Candidate {
  align-items: center;
  display: flex;
}

.PositionShareCard__Candidate .Headshot {
  margin-right: 1rem;
}

@media (min-width: 576px) {
  .PositionShareCard__CandidateName {
    display: flex;
  }
}

.PositionShareCard__CandidateName h4 {
  margin: 0;
}

.PositionShareCard__Selection {
  margin-top: 1.5rem;
}

.PositionShareCard__Icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
}

@media (max-width: 576px) {
  .PositionShareCard__Icon {
    margin-left: 0;
  }
}

.PositionShareCard__Icon--danger,
.PositionShareCard__Retention--danger {
  color: var(--brand-danger);
}

.PositionShareCard__Icon--success,
.PositionShareCard__Retention--success {
  color: var(--brand-success);
}

.PositionShareCard__Retention {
  font-size: 0.8rem;
}
