@import 'theme.scss';

.border {
  border-bottom: 1px solid $color-gray-light;
}
.container {
  height: 100%;
  margin-top: 0;
  padding: 0;
  overflow-y: auto;
  width: 100%;

  &.repListContainer {
    overflow: unset;
  }
}

.listItem {
  list-style: none;
  margin-top: 0;
  padding: $padding-compact;

  &:not(.meetYourRepListStyles) {
    transition: background-color 0.2s;
    &:hover {
      background-color: $color-gray-light;
    }
  }
}

.selected {
  background-color: $color-gray-extra-light;
  border-left: 3px solid getColor(var(--color-primary), $color-primary);
  box-sizing: border-box;
  padding-left: calc($space-xxs - 3px);
}

.meetYourRepListStyles {
  padding: $padding-cozy;
}
