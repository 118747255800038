@import 'theme.scss';

.newsItem {
  box-shadow: 0 0 12px 0 rgba(180, 180, 180, 0.5);
  display: grid;
  grid-auto-flow: column;
  margin-bottom: $space-xs;
  padding: $space-xs;
  text-align: left;
}

.title {
  margin-bottom: $space-xxs;
}

.description {
  margin-bottom: $space-xs;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-xxs;
}

.favicon {
  height: 25px;
  margin-bottom: $space-xxs;
  min-width: 25px;
  padding-right: $space-xxs;
}

/* Large: 100x100 */
.image {
  height: 125px;
  margin-left: $space-xxs;
}

@media (min-width: $breakpoint-md) {
  .image {
    height: 175px;
    margin-left: $space-xs;
  }
}
