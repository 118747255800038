@import 'theme.scss';

.formGroup {
  margin-bottom: 1rem;
}

.label {
  font-weight: 800;
  margin-bottom: $space-xs;
}

.labelContainer {
  align-items: center;
  display: flex;
}

.input {
  border: 1px solid $color-gray-dark;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25;
  padding: 1rem;
  width: 100%;
}

.invalid {
  border-color: var(--brand-danger);
}

.errorMessage {
  color: var(--brand-danger);
}

.tooltipContainer {
  max-width: 20rem;
  padding: 0 1rem;
}

.tooltipTrigger {
  background: transparent;
  border: 0;
  color: $color-gray-dark;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: auto;
  padding: 0;
}

.tooltipTrigger:hover {
  color: var(--link-color--hover);
}
