@import 'theme.scss';

.SharedBallotHeader {
  margin-bottom: 6rem;
}

.SharedBallot .SharedBallotHeader h2 {
  margin-bottom: 0;
}

.SharedBallotHeader input,
.SharedBallotHeader textarea {
  border: none;
  box-shadow: 0 2px 8px 0 rgba(113, 111, 111, 0.22);
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.SharedBallotHeader input {
  font-size: 1.75rem;
  font-weight: 600;
}

.SharedBallotHeader .Button {
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.SharedBallotHeader__Edit {
  color: var(--link-color);
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;
}

.SharedBallotHeader__Edit:hover {
  color: var(--link-color--hover);
}

.SharedBallotHeader__Field--editable {
  border: 1px solid $color-gray-light;
  padding: 1rem;
  position: relative;
}

.SharedBallotHeader__Field--editable:hover {
  background: $color-gray-extra-light;
  cursor: pointer;
}

.SharedBallotHeader__Field--editable sup {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.SharedBallotHeader__Actions {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
}
