@import 'theme.scss';

.bio {
  color: $color-black;
  text-align: center;
  h2 {
    letter-spacing: 2px;
    margin: 0 0 $space-sm;
    text-transform: uppercase;
  }
}

// these styles are applied to markdown
// todo - apply markdoqn guidelines to make sure the html we receive works semantically with our document flow
.blob {
  margin-bottom: $space-sm;
  h1 {
    font-size: $text-md;
    letter-spacing: 1px;
    margin-top: $space-sm;
    text-align: center;
  }
  p {
    margin: $m0;
  }
}
/* Player ratio: 100 / (1280 / 720) */
.videoWrapper {
  margin-bottom: $space-sm;
  padding-top: 56.25%;
  position: relative;
}

.video {
  left: 0;
  position: absolute;
  top: 0;
}
