@import 'theme.scss';

.searchResultContainer {
  align-items: center;
  color: $color-black !important;
  display: flex;
  background: none;
  border: none;
  text-align: left;
  min-height: $space-sm;
  text-decoration: none;
  width: 100%;
  font-size: $text-sm;
  &:hover {
    color: $color-black;
    cursor: pointer;
  }
}

.positionIcon {
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textContent {
  line-height: $line-height-xs;
  margin-left: $space-xs;
  font-weight: $font-weight-bold;
}

.titleText {
  margin-bottom: $space-xxxs;
}

.subtitleText {
  color: $color-gray-dark;
  font-size: $text-xs;
  margin-bottom: $space-xxxs;
}

.noHover {
  &:hover {
    cursor: unset;
  }
}

.searchResultContainer > div:first-child {
  display: flex;
}

.meetYourRepListItem {
  flex-direction: column;
  align-items: flex-start;
  padding: $padding-cozy;
  background: $color-white;
  border: 1px solid $color-gray-light;
  text-align: left;
  border-radius: 1rem;
  transition: background-color 0.2s;

  @media (max-width: 767px) {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $color-gray-extra-light;
  }

  & > div:first-child {
    width: inherit;
  }

  .textContent {
    margin-left: 0;
    font-weight: $font-weight-regular;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:not(.listItemDescription) {
      width: inherit;
    }
  }

  img {
    margin-right: $margin-compact;
  }

  .titleText {
    font-weight: $font-weight-bold;
  }

  .listItemDescription {
    margin-top: $margin-compact;
    padding-top: $padding-compact;
    border-top: 1px solid $color-gray-light;
    font-size: $text-xs;
    line-height: $line-height-md;
  }

  .expandRight {
    right: $space-sm;

    svg {
      color: var(--brand-danger);
    }
  }
}

.warning {
  margin: 0 $space-xxxs;
  color: var(--brand-warning);
}
