@import 'theme.scss';

.formInner {
  margin-top: 3rem;
  p {
    font-size: 0.8rem;
    font-style: italic;
    line-height: 16px;
    color: $color-gray-dark;
    text-align: left;
  }
}

.input {
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid $color-gray;
  border-radius: 0.25rem;
  color: $color-black;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.label {
  display: block;
  font-weight: bold;
  text-align: left;
  width: 100%;
}

.checkbox {
  display: block;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

.button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
