.DistrictList {
  display: flex;
  flex-flow: wrap;
  font-size: 0.875rem;
  justify-content: center;
  text-align: center;
}

.DistrictListItem {
  background: rgba(255, 255, 255, 0.1);
  margin: 0.5rem;
  padding: 0.625rem 1rem;
}
