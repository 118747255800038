@import 'theme';

.Hero {
  background: $color-gray-light;
  margin-left: auto;
  margin-right: auto;
  max-width: 991px;
  padding: 1rem;

  @media (min-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (min-width: 991px) {
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.Hero__Header {
  color: var(--color-primary);
  line-height: 1.6;
  text-align: center;

  p {
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
      line-height: 1.25;
    }
    p {
      width: 65%;
    }
  }

  @media (min-width: 991px) {
    h1 {
      font-size: 2.5rem;
      line-height: 1.15;
    }
  }

  @media (min-width: 1100px) {
    h1 {
      font-size: 3rem;
    }
  }
}

.Hero__CTA {
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-area: form;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  @media (min-width: 768px) {
    .AddressForm {
      max-width: 800px;
    }
  }
}
