@import 'theme.scss';

.Logo {
  max-height: $nav-height;
  margin: 0.5rem 1rem;
}

@media (min-width: 768px) {
  .Logo {
    max-height: $nav-height-lg;
  }
}

@media (min-width: 992px) {
  .Logo {
    margin-left: 2rem;
  }
}
