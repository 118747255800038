.calenderIcon {
  margin-right: 0.5rem;
}

.electionDay {
  font-weight: bold;
}

.title {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
}
