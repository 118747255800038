.root abbr {
  text-decoration: none;
}

.root abbr::after {
  content: '/';
}

.root abbr:last-child::after {
  content: '';
}
