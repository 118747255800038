@import 'theme.scss';

.modal {
  background-color: var(--color-primary-lightened);
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.65);
  color: $color-white;
  left: 50%;
  max-width: 35rem;
  padding: $space-sm;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @media (max-width: $breakpoint-sm) {
    width: 80%;
  }

  h2 {
    margin-top: $space-xs;
  }
}

.modalOverlay {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.link,
.link:visited {
  color: $color-white;
  display: block;
  font-size: $text-xs;
}

.button {
  color: var(--color-primary);
  margin-top: $space-sm;
}
