@import 'theme.scss';

.dropdown {
  background-color: $color-white;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(55, 55, 55, 0.5);
  color: $color-black;
  height: 40rem;
  margin-top: 1.5rem;
  padding: 1.5rem 1rem;
  position: absolute;
  width: 44rem;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 1150px) {
    margin-top: 2rem;
  }
}

.collapsed {
  @media (min-width: 991px) {
    height: auto;
    padding-bottom: 0.5rem;
  }
}

.savedSearchTag {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.resultsHeader {
  color: $color-gray-dark;
  border-bottom: 1px solid $color-gray;
  margin-bottom: 0 !important;
  padding-bottom: 1rem;
}

.mobile {
  box-shadow: unset;

  height: 100%;
  margin-top: 0;
  padding-top: 0;
  padding-right: 0;
  width: 100%;

  @media (min-width: 768px) and (max-width: 991px) {
    box-shadow: 0 0 10px rgba(55, 55, 55, 0.5);
    margin-top: 0;
    padding-top: 2rem;
  }

  .savedContainer {
    display: flex;
    overflow: scroll;
  }
}

.suggestion {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
