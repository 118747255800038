@import 'theme.scss';

.root {
  height: calc(100vh - #{$nav-height-lg});
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: $nav-height-lg;
  }
}
