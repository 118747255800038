@import 'theme.scss';

.ActionHeader {
  background-color: var(--color-primary);
  color: $color-white;
}

.ActionHeader__Inner {
  margin: 0 auto;
  max-width: 1400px;
  padding: 2rem 1rem;
}

.ActionHeader__Inner > p {
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}

.ActionHeader__Logo {
  display: none;
  margin-right: 1.5rem;
  width: 50px;
}

.ActionHeader__Text {
  flex: 1;
  margin-right: 1.5rem;
  text-align: center;
}

.ActionHeader__Text h1 {
  font-size: 1.375rem;
}

.ActionHeader__Text p {
  font-weight: 300;
  margin: 0;
}

.ActionHeader .AddressForm {
  flex: 1;
  margin-top: 1.5rem;
}

.ActionHeader .AddressForm__submit {
  width: 100%;
}

.ActionHeader .AddressForm__Hint {
  color: $color-gray;
}

@media (min-width: 768px) {
  .ActionHeader .AddressForm {
    margin-top: 0;
  }

  .ActionHeader .AddressForm__Hint {
    margin-top: 0;
  }

  .ActionHeader__Inner {
    align-items: center;
    display: flex;
  }

  .ActionHeader__Inner > p {
    font-size: 1rem;
  }

  .ActionHeader__Logo {
    display: block;
  }

  .ActionHeader__Text {
    text-align: left;
  }
}
