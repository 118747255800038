.AuthModal .Modal__Body > section,
.AuthModal .Modal__Body > footer > section {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
}

.AuthModal__Error {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 25rem;
}

.AuthModal__header {
  margin-bottom: 2rem;
  text-align: center;
}

.AuthModal__header p {
  margin: 0;
}

.AuthModal__header .Logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 225px;
}

.AuthModal__Social {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.AuthModal__StateSwitcher {
  align-items: center;
  border-top: 1px solid #979797;
  display: flex;
  margin-top: 3rem;
  padding-top: 1rem;
}

.AuthModal__StateSwitcher > div {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .Button.AuthModal__Btn {
    display: flex;
  }
}
