@import 'theme.scss';

.newsContainer {
  padding-bottom: $space-sm;
  text-align: center;
  h2 {
    letter-spacing: 2px;
    margin: 0 0 $space-sm;
    text-transform: uppercase;
  }
}
