@import 'theme.scss';

.contactInfo {
  a {
    display: block;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: $breakpoint-sm) {
      min-height: 2rem;
      margin-bottom: $space-xxs;
    }
  }
}
