@import 'theme.scss';

.drawerContainer {
  box-shadow: 1px 1px 4px 1px $color-gray;
  margin-bottom: $space-md;
  width: 100%;
}

.drawerOuter {
  display: flex;
  padding: $padding-cozy;
  justify-content: space-between;
  background: none;
  border: none;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  h3 {
    color: var(--color-primary);
    margin-bottom: $m0;
    margin-top: $space-xxs;
  }
}

.drawerOuterOpen {
  border-bottom: 1px solid $color-gray;
}

.drawerArrow {
  color: var(--color-primary);
}

.disabled {
  .drawerOuter {
    h3 {
      color: $color-gray;
    }
    .drawerArrow {
      color: $color-gray;
    }
    &:hover {
      cursor: default;
    }
  }
}

.drawerInner {
  @media (min-width: $breakpoint-sm) {
    padding-top: $space-xs;
  }
}
