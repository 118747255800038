@import 'theme.scss';

.signatureContainer {
  align-items: flex-start;
  border: 1px solid $color-gray-dark;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
}

.signaturePad {
  border-bottom: 1px solid $color-gray-dark;
}

.signaturePad__error {
  border: 1px solid var(--brand-danger);
}

.footer {
  background-color: $color-gray-extra-light;
  border: none;
  cursor: pointer;
  padding: $space-xxs;
  width: 100%;
}

.clearBtn {
  padding: $space-xxs $space-xs;
}
