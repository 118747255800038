@import 'theme.scss';

.Spinner {
  animation: spin 1s ease-in-out infinite;
  border: 0.35rem solid var(--spinner-color-ring);
  border-radius: 50%;
  border-top-color: var(--spinner-color);
  display: inline-block;
  height: $space-sm;
  width: $space-sm;
}

.Spinner--lg {
  border-width: 0.5rem;
  height: $space-md;
  width: $space-md;
}

.Spinner--sm {
  border-width: 0.2rem;
  height: $space-sm;
  width: $space-sm;
}

.Spinner--xs {
  border-width: 0.2rem;
  height: $space-xs;
  width: $space-xs;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
