@import 'theme.scss';

/* When A/B testing the sections are wrapped in a <span> */
.candidateDetails > section,
.candidateDetails > span > section {
  border-bottom: 1px solid $color-gray;
  margin: $margin-comfortable-vertical-only;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}

/* When A/B testing the sections are wrapped in a <span> */
.candidateDetails > section h3,
.candidateDetails > span > section h3 {
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: $space-sm;
  text-transform: uppercase;
}

.candidateDetails__Blob {
  margin-bottom: $space-sm;
}

.candidateDetails__Blob h1 {
  // these are markdown blobs and we do not have control over the semantic html here
  font-size: $text-md;
  letter-spacing: 1px;
  margin-top: $space-sm;
  text-align: center;
}

.candidateDetails__MarkdownBlob {
  text-align: left;
  padding: 1rem;
}
