@import 'theme.scss';

.ShareCard {
  background: $color-white;
  box-shadow: 0 0 12px 0 rgba(118, 118, 118, 0.26);
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.ShareCard h3 {
  color: $color-gray-dark;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .ShareCard {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
