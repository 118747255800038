@import 'theme.scss';

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-headings);
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 1.75rem;
  font-weight: 600;
}

h2 {
  font-size: 1.125rem;
  font-weight: 600;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

a,
a:visited {
  color: var(--link-color);
}

a:active,
a:focus,
a:hover {
  color: var(--link-color--hover);
}

p,
dt,
dd {
  line-height: 1.5;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
}

textarea:focus,
input:focus {
  outline: none;
}

/* Tooltips */
.tooltip-container {
  font-size: 0.875rem;
  padding: 1rem;
  text-align: center;
  z-index: 2147483646;
}

/* Placeholders */
::placeholder {
  color: #7e7e7e;
  font-style: italic;
}

/* Removes red border around required inputs in FF */
/* @see https://github.com/twbs/bootstrap/issues/21323#issuecomment-380948197 */
:not(output):-moz-ui-invalid:not(:focus) {
  box-shadow: none;
}
:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
  box-shadow: none;
}

@media print {
  @page {
    margin-bottom: 15mm;
    margin-left: 10mm;
    margin-right: 10mm;
    margin-top: 15mm;
  }
}

/* Class added to <body>. Prevents page from scrolling behind modal. */
.ReactModal__Body--open {
  overflow: hidden;
}
